<template>
	<figure class="course-editor-block-container">
		<p>{{ content }}</p>
		<video width="320" height="240" controls>
			<source :src="pathUrl" type="video/mp4" />
			Your browser does not support the video tag.
		</video>
	</figure>
</template>

<script>
export default {
	name: "CourseEditorBlockParagraph",
	props: ["file"],
	computed: {
		pathUrl() {
			return this.file?.public_url;
		}
	}
};
</script>

<style></style>
